import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { Alert, Button, Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const BCMGuidesCanada: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page play-on-pc-page bcm'} game="bcm">
      <ul className="breadcrumb">
        <li>
          <Link to="/black-clover/">Black Clover M</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/black-clover/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>How to play on Canada server</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/blackclover/categories/cat_global.png"
            alt="How to play on KR/JPN serves"
          />
        </div>
        <div className="page-details">
          <h1>How to play on Canada server</h1>
          <h2>
            This guide will help you play on the Canada soft launch server!
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          The Canada server for Black Clover mobile will launch on the 17th of
          August! Still, this is only a soft launch for the game and when the
          Global release will happen in the future, the Canada server will be
          joined by EU, NA, Latin America and other servers. Still, if you want
          to get a headstart and play the first English version of the game,
          this guide will help you!
        </p>
        <p>
          First thing. If you're not from Canada,{' '}
          <strong>
            you won't be able to download the game from the Google/Apple markets
          </strong>
          . And you have two options to resolve the issue:
        </p>
        <ul>
          <li>Download an APK from QooApp or other website on your phone,</li>
          <li>Download an emulator and install the APK there.</li>
        </ul>
        <p>
          If you plan to reroll, we suggest using{' '}
          <strong>
            the emulator as this will make the whole process a lot easier
          </strong>{' '}
          and furthermore, emulators can record macros which can further improve
          both the reroll process and the daily grind.
        </p>
        <p>
          The emulator we suggest using is <strong>BlueStacks</strong> as it's
          fast and reliable - especially when you want to setup multiple
          instances.
        </p>
        <p>
          You can download Bluestacks from our link below and by doing so
          support Prydwen!
        </p>
        <div className="banner play-on-pc bcm">
          <div className="cta">
            <h4>Play Black Clover Mobile on PC</h4>
            <OutboundLink href="https://bstk.me/uFDBDDdNm" target="_blank">
              <Button variant="primary">Play now on BlueStacks</Button>
            </OutboundLink>
          </div>
        </div>
        <SectionHeader title="How to install the game" />
        <Alert variant="primary">
          <p>
            Black Clover Mobile Canada soft launch is{' '}
            <strong>region locked</strong>. This means if you live anywhwere
            else, you will need to use a VPN to login to the game and bypass the
            restriction. We suggest using{' '}
            <a href="https://protonvpn.com/" target="_blank" rel="noreferrer">
              Proton VPN
            </a>
            , but the Canadian gateway is available only in the premium version.
          </p>
          <p>
            Keep in mind that you only need to{' '}
            <strong>
              {' '}
              have the VPN running when you login to the game - once you do, you
              can safely disable it
            </strong>
            .
          </p>
        </Alert>
        <h5>I have a Canadian Google/Apple account</h5>
        <p>
          If you have a Canadian Google (or Apple) account, here are the
          official links to the game in Google/Apple stores. All you need to do
          is download the game and you're done!
        </p>
        <ul>
          <li>
            <a
              href="https://play.google.com/store/apps/details?id=com.garena.game.bcw"
              target="_blank"
              rel="noreferrer"
            >
              Black Clover Mobile Canada on Google Play
            </a>
          </li>
          <li>
            <a
              href="https://apps.apple.com/ca/app/black-clover-m/id1661053740"
              target="_blank"
              rel="noreferrer"
            >
              Black Clover Mobile Canada on Apple Store
            </a>
          </li>
        </ul>
        <h5>I don't have an Canada Google/Apple account</h5>
        <p>Well, things won't be as easy in this variant.</p>
        <p>
          If you decide to play on the emulator or your phone, you will have to
          download the APK for the game directly from{' '}
          <a
            href="https://apkcombo.com/fr/black-clover-m/com.garena.game.bcw/download/apk"
            target="_blank"
            rel="noreferrer"
          >
            APKCombo here.
          </a>
        </p>
        <ul>
          <li>
            On your phone, you just have to click the link above, download the
            APK and install it (additional permissions might be required),
          </li>
          <li>
            On BlueStacks, you will have to create a new instance and then just
            drag and drop the APK on it - installation will automatically start.
          </li>
        </ul>
        <p>
          On the release day, the game should also be available in QooApp, but
          currently it isn't.
        </p>
        <p>
          Here's the very important step.{' '}
          <strong className="red">
            If you plan to reroll using the multi-instance trick, don't launch
            the game.
          </strong>{' '}
          If you open it, even for a few seconds, the device ID will be saved
          somewhere in the game and if you clone the instance after, you won't
          be able to open it at the same time in another one. That's why you
          need to clone the instance first and only reroll on the cloned
          instances.
        </p>
        <p>
          Before you launch the game,{' '}
          <strong>
            make sure to open Proton VPN (or another VPN) and connect to an
            endpoint in Canada
          </strong>
          . If not, whenever you will try to login as a guest (or create an
          account) the game will kick you out. Once you pass the wall and the
          game will start downloading the patch (around 1GB) you can safely
          disable the VPN. Once you're done rerolling, the VPN isn't needed
          anymore.
        </p>
        <p>Now you're ready to play, have fun!</p>
        <p>If you want to learn who to reroll for, check our other guide:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Reroll guide"
            link="/black-clover/guides/reroll"
            image={
              <StaticImage
                src="../../../images/blackclover/categories/cat_reroll.png"
                alt="Reroll guide"
              />
            }
            updated
          />
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default BCMGuidesCanada;

export const Head: React.FC = () => (
  <Seo
    title="How to play on Canada server | Black Clover M | Prydwen Institute"
    description="This guide will help you play on the Canada soft launch server!"
  />
);
